const presentation = document.querySelector("#presentation");
const realisation = document.querySelector("#realisations");

const arrowBanner = document.querySelector("#banner .arrow");
const arrowPresentation = document.querySelector("#presentation .arrow");

arrowBanner.addEventListener("click", () => {
  presentation.scrollIntoView({ behavior: "smooth" });
});

arrowPresentation.addEventListener("click", () => {
  realisations.scrollIntoView({ behavior: "smooth" });
});

const titleBanner = document.querySelector("#banner .title span");
const regex = "i";
const array = titleBanner.textContent.split("");
const index = titleBanner.textContent.search(regex);
array[index] = "<span class='title__leaf'>i</span>";
titleBanner.innerHTML = array.join("");

let rellax = new Rellax(".rellax", {
  speed: -2,
  center: true,
  wrapper: null,
  round: true,
  vertical: true,
  horizontal: false,
});
